import { Global, css } from '@emotion/react';
import theme from '../../themes/common/theme';

export const GlobalStyle = () => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
      }

      html {
        position: relative;
        min-height: 100%;
      }

      body {
        margin: 0;
        margin-bottom: 240px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #fff;
        color: ${theme.colors.globalFontGrey};
        font-family: Lato, Arial, sans-serif;
        overflow-x: hidden;
        max-width: 100vw;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${theme.colors.globalFontGrey};
        font-weight: 500;
        margin: 0 0 8px 0;
      }

      p {
        font-weight: 400;
        margin: 0;
      }

      ul {
        margin: 0;
      }

      li {
        font-weight: 400;
        margin-bottom: 5px;
      }

      a {
        color: ${theme.colors.linkBlue};
        text-decoration: none;

        &:hover {
          color: ${theme.colors.linkBlueHover};
          text-decoration: underline;
          cursor: pointer;
        }
      }

      button,
      input[type='submit'],
      input[type='reset'] {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        font-weight: bold;
      }

      input,
      select,
      textarea {
        font-family: Lato, Arial, sans-serif;
        font-size: 16px;
        border: 1px solid ${theme.colors.formFieldOutlineGreyHover};
        border-radius: 4px;
        padding: 8px;
        color: ${theme.colors.globalFontGrey};

        ::placeholder {
          font-size: 16px;
          color: ${theme.colors.placeholderGrey};
        }
      }

      img {
        max-width: 100%;
      }

      .formfield-required:after {
        content: ' *';
        color: ${theme.colors.dangerRed};
        font-weight: bold;
      }

      .formfield-hidden {
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0 !important;
      }

      .formfield-visible {
        max-height: 200px;
        overflow: auto;
        margin-bottom: 32px;
      }

      /* Modal animations */
      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes fadeout {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      .fa-stack {
        vertical-align: middle;
      }

      // Hides any google captcha branding added to the page
      // Be sure to include the necessary disclaimer:
      // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
      .grecaptcha-badge {
        visibility: hidden;
      }
    `}
  />
);
