import { darken } from 'polished';

const hoverDarken = (color: string) => {
  return darken(0.1, color);
};

const disabledDarken = (color: string) => {
  return darken(0.3, color);
};

// Temporary place to hold theme variables until we find a way to share
// variables between SCSS and JS, e.g.
// https://til.hashrocket.com/posts/sxbrscjuqu-share-scss-variables-with-javascript

export const baseColors = {
  successGreen: '#1ba750',
  successGreenHover: hoverDarken('#168640'),
  danger: '#dc3545',
  grayLighter: '#e9ecef',
  grayLight: '#ced4da',
  grayDark: '#343a40',
  superLightGrey: '#f3f4f5',
  globalFontGrey: '#333',
  metaGrey: '#B0B0B0',
  darkBlue: '#1D3747',
  notSoDarkBlue: '#334A55',
  formFieldOutlineGrey: '#ced4da',
  formFieldOutlineGreyHover: hoverDarken('#ced4da'),
  placeholderGrey: '#495057',
  lightBlack: '#0a0a0a',
  listGrey: '#1a1a1a',
  linkBlue: '#0054a6',
  inPartOrange: '#f9a664',
  inPartGreen: '#C8DC87',
  inPartBlue: '#A2D2DE',
  infoButtonBlue: '#84b4c0',
  aquaBlue: '#17a2b8',
  dangerRed: '#dc3545',
  bannerErrorBackground: '#edd6d6',
  bannerErrorText: '#943032',
  bannerSuccessBackground: '#cdead1',
  bannerSuccessText: '#184619',
  ghost: 'none',
  lightGrey: 'rgba(0, 0, 0, 0.1)',
};

const modifiedColors = {
  linkBlueHover: hoverDarken(baseColors.linkBlue),
  inPartGreenHover: hoverDarken(baseColors.inPartGreen),
  inPartBlueHover: hoverDarken(baseColors.inPartBlue),
  inPartOrangeHover: hoverDarken(baseColors.inPartOrange),
  inPartOrangeDisabled: disabledDarken(baseColors.inPartOrange),
  infoButtonBlueHover: hoverDarken(baseColors.infoButtonBlue),
  infoButtonBlueDisabled: disabledDarken(baseColors.infoButtonBlue),
  successGreenDisabled: disabledDarken(baseColors.successGreen),
  dangerRedHover: hoverDarken(baseColors.dangerRed),
  dangerRedDisabled: disabledDarken(baseColors.dangerRed),
};

const discoverColors = {
  greyBlue: '#3D5D74',
  turquoise: '#73D8CE',
  grey: '#5F5F5F',
  green: '#A7D6A5',
};

export const colors = {
  ...baseColors,
  ...modifiedColors,
  ...discoverColors,
};

export const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1400px',
  mobile: 400,
  tablet: 750,
  small: 1120,
  academicSmall: 1200,
  medium: 1500,
  fourColumnWidth: 1490,
  threeColumnWidth: 1116,
  twoColumnWidth: 744,
  oneColumnWidth: 370,
};
