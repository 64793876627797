import React, { useState, useEffect, ReactElement } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FaHome } from 'react-icons/fa';
import { PoweredBy } from './PoweredBy';
import { ENV } from '@/constants';
import { GlobalStyle } from './GlobalStyle';
import * as S from './Layout.styles';
import Logo from './Logo';

export const Layout: React.ElementType = ({ children }): ReactElement => {
  const { query, pathname } = useRouter();

  const campaignShortId = query.campaignId as string;
  const utmTags = {
    utm_source: campaignShortId ? 'discover-campaign' : 'discover-homepage',
    utm_medium: 'discover-public',
    utm_content: 'header',
    ...(campaignShortId ? { utm_campaign: campaignShortId } : {}),
  };

  const [returnTo, setReturnTo] = useState<string>('/');
  useEffect(() => {
    if (typeof window !== 'undefined' && pathname.startsWith('/discovercalls')) {
      setReturnTo(`${window.location.protocol}//${window.location.host}/discovercalls/${campaignShortId}`);
    }
  }, [pathname]);

  const loginUrl = `${ENV.NEXT_PUBLIC_COMPANIES_APP_URL}/login?userType=academic&returnTo=${returnTo}`;
  const registerUrl = `${
    ENV.NEXT_PUBLIC_COMPANIES_APP_URL
  }/register/academic?returnTo=${returnTo}&${new URLSearchParams(utmTags).toString()}`;

  return (
    <>
      <GlobalStyle />

      <S.Header>
        <div className="logo">
          <Link href="/" aria-label={`${ENV.NEXT_PUBLIC_ORGANISATION_NAME} home`}>
            <Logo title={`${ENV.NEXT_PUBLIC_ORGANISATION_NAME} Logo`} />
          </Link>
        </div>

        <div className="links">
          <a href={loginUrl} className="btn">
            Login
          </a>

          <a href={registerUrl} className="btn">
            Register
          </a>

          <Link href="/" className="btn home">
            <FaHome size="18px" />
          </Link>
        </div>
      </S.Header>

      <S.Content>
        <div>{children}</div>
      </S.Content>

      <S.Footer>
        <div className="strapline" tw="mb-8">
          <PoweredBy
            newLogo={ENV.NEXT_PUBLIC_ENABLE_NEW_LOGO}
            organisationName={ENV.NEXT_PUBLIC_ORGANISATION_NAME}
            strapline={true}
            linkUrl="https://in-part.com"
          />
        </div>

        <div className="captcha-disclosure">
          <p>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>
        </div>
      </S.Footer>
    </>
  );
};
