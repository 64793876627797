import styled from '@emotion/styled';
import Logo from './Logo';

const logoSvg =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='114px' height='51px' viewBox='0 0 114.4115 51.0012'%3E%3Cg id='logo'%3E%3Ctitle%3Elogo%3C/title%3E%3Cg%3E%3Cg%3E%3Crect x='0.0001' y='0.0001' width='5.609' height='38.1822' style='fill: %23646464'/%3E%3Cpolygon points='15.684 18.285 15.216 16.516 14.903 16.516 14.903 18.285 14.903 38.182 9.762 38.182 9.762 0 14.748 0 20.202 19.89 20.669 21.659 20.982 21.659 20.982 19.89 20.982 0 26.125 0 26.125 38.182 21.138 38.182 15.684 18.285' style='fill: %23646464'/%3E%3Crect x='30.0686' y='21.7133' width='7.5303' height='4.9313' style='fill: %23646464'/%3E%3Cpath d='M46.3091,4.3872h5.61c4.8839,0,9.8624,2.6485,9.8624,11.4281,0,8.7745-4.9784,11.475-9.8624,11.475V42.5694h-5.61Zm5.61,17.1893c3.5344,0,4.211-1.2929,4.211-5.5566,0-4.2593-.6766-5.9187-4.211-5.9187Z' transform='translate(-4.7634 -4.3871)' style='fill: %23646464'/%3E%3Cpath d='M63.033,42.5693,68.794,4.3872h6.857l5.7647,38.1822H75.9639l-1.0334-7.796H69.53l-1.0488,7.796Zm9.5025-25.7679-.1562-.9861H72.066l-.1566.9861L70.2987,28.8479h3.8492Z' transform='translate(-4.7634 -4.3871)' style='fill: %23646464'/%3E%3Cpath d='M94.0445,42.5694,91.1326,26.4605H89.63V42.5694H84.01V4.3871h5.62c6.638,0,9.55,3.8432,9.55,11.0134,0,3.7884-.9864,7.2168-3.1155,8.6728l3.5853,18.4962ZM89.63,9.9447V21.271c1.55,0,4.1486-.2115,4.1486-5.667,0-5.4483-2.5989-5.6593-4.1486-5.6593' transform='translate(-4.7634 -4.3871)' style='fill: %23646464'/%3E%3Cpolygon points='96.027 5.769 96.027 0 112.23 0 112.23 5.769 106.934 5.769 106.934 38.182 101.319 38.182 101.319 5.769 96.027 5.769' style='fill: %23646464'/%3E%3C/g%3E%3Crect y='43.3662' width='38.1352' height='7.635' style='fill: %239ccae2'/%3E%3Crect x='38.1352' y='43.3662' width='38.1427' height='7.635' style='fill: %23c3e483'/%3E%3Crect x='76.2779' y='43.3662' width='38.1336' height='7.635' style='fill: %23f7a95f'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";

const PoweredByDiv = styled.div`
  display: flex;
  align-items: stretch;

  .logo {
    display: block;
    color: #212529;

    span.pow {
      display: block;
      margin-bottom: 2px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: light;
    }

    img {
      display: block;
      height: 36px;

      ${({ theme }) => theme.media.lg`
        height: 45px;
      `}
    }
  }

  span.strap {
    display: flex;
    align-items: center;

    max-width: 220px;
    border-left: 1px solid #c0c0c0;
    padding-left: 10px;
    margin-left: 10px;

    font-size: 13px;
    line-height: 16px;

    ${({ theme }) => theme.media.lg`
      max-width: 450px;

      padding-left: 20px;
      margin-left: 20px;

      font-size: 17px;
      line-height: 22px;
    `}
  }
`;

type Props = {
  linkUrl?: string;
  newLogo: boolean;
  organisationName: string;
  strapline?: boolean;
  straplineLinkUrl?: string;
};

export function PoweredBy({ linkUrl, newLogo, organisationName, strapline = false, straplineLinkUrl }: Props) {
  const logo = newLogo ? (
    <Logo title={organisationName} />
  ) : (
    <img src={logoSvg} alt={`Powered by ${organisationName}`} />
  );

  return (
    <PoweredByDiv>
      {linkUrl ? (
        <a href={linkUrl} target="_blank" className="logo">
          <span className="pow">Powered By</span>
          {logo}
        </a>
      ) : (
        <span className="logo">
          <span className="pow">Powered By</span>
          {logo}
        </span>
      )}
      {strapline ? (
        <span className="strap">
          <div>
            An online matchmaking platform for university-industry collaboration.{' '}
            <a href={straplineLinkUrl || linkUrl} target="_blank" aria-label={`Learn more about ${organisationName}`}>
              Learn More
            </a>
          </div>
        </span>
      ) : null}
    </PoweredByDiv>
  );
}
