const defaults = {
  googleAnalytics: false,
};

export const parseDebug = (debugStr: string) =>
  debugStr.split(';').reduce(
    (a, key) => ({
      ...a,
      [key]: true,
    }),
    defaults,
  );
