export const ENV = {
  SERVER_API_HOST: process.env.SERVER_API_HOST,

  // Public (available to client) env vars
  NEXT_PUBLIC_DEBUG: process.env.NEXT_PUBLIC_DEBUG || '',
  NEXT_PUBLIC_CLIENT_API_HOST: process.env.NEXT_PUBLIC_CLIENT_API_HOST,
  NEXT_PUBLIC_GA_TRACKING_ID: process.env.NEXT_PUBLIC_GA_TRACKING_ID,
  NEXT_PUBLIC_GA_4_TRACKING_ID: process.env.NEXT_PUBLIC_GA_4_TRACKING_ID,
  NEXT_PUBLIC_CAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY || '6Lf8MfMUAAAAAD1GwCAkTY74tz7oJGRFzRMlSSF0',
  NEXT_PUBLIC_COMPANIES_APP_URL: process.env.NEXT_PUBLIC_COMPANIES_APP_URL || 'http://localhost:3000',
  NEXT_PUBLIC_UNIVERSITY_DASHBOARD_URL:
    process.env.NEXT_PUBLIC_UNIVERSITY_DASHBOARD_URL || 'http://localhost:3000/university-dashboard',
  NEXT_PUBLIC_S3_BUCKET_URL_ASSETS: process.env.NEXT_PUBLIC_S3_BUCKET_URL_ASSETS,
  NEXT_PUBLIC_ENABLE_NEW_LOGO: process.env.NEXT_PUBLIC_ENABLE_NEW_LOGO === 'true',
  NEXT_PUBLIC_ORGANISATION_NAME: process.env.NEXT_PUBLIC_ENABLE_NEW_LOGO === 'true' ? 'Inpart' : 'IN-PART',
} as const;
