import styled from '@emotion/styled';

export const Header = styled.nav`
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 0 20px;

  @media (min-width: 768px) {
    position: fixed;
    top: 0;
  }

  .logo {
    margin-top: 5px;
    margin-right: 20px;

    img {
      max-height: 35px;
    }
  }

  .links {
    margin-left: auto;
    display: flex;
    align-items: center;

    .btn {
      display: block;
      padding: 6px 16px;
      margin: 0 10px 0 0;
      font-weight: bold;
      border: none;
      border-radius: 6px;
      background-color: #0054a6;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }

      &.home {
        color: #333;
        background: none;
        padding-left: 8px;
        padding-right: 8px;
        margin: 0;
      }
    }
  }
`;

export const Content = styled.main`
  @media (min-width: 768px) {
    padding-top: 70px;
  }

  & > div {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1500px;

    @media (min-width: 768px) {
      padding: 0 30px;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  align-items: center;
  padding: 2.5rem 0 1rem 0;
  background-color: #f9f9f9;

  .strapline {
    margin-bottom: 2rem;
  }

  .captcha-disclosure {
    border-top: 1px solid #e5e7eb;
    padding: 1rem 1rem 0;

    p {
      padding: 0;
      margin: 0;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: rgb(156, 163, 175);

      a {
        color: rgb(156, 163, 175);
        text-decoration: underline;
      }
    }
  }
`;
