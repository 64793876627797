import { breakpoints, colors } from './variables';
import { css } from '@emotion/react';

export interface Breakpoints {
  [k: string]: string | number;
}

export interface Colors {
  [k: string]: string;
}

export interface Media {
  [k: string]: (styles, ...args) => {};
}

export type CommonBreakpoints = Breakpoints & { [p in keyof typeof breakpoints]: string | number };

export type CommonColors = Colors & { [p in keyof typeof colors]: string };

export type CommonMedia = Media & { [p in keyof typeof breakpoints]: (styles, ...args) => {} };

const mediaFunc = function (template, props, suffix = '') {
  return [
    props['xs' + suffix] !== undefined ? template(props['xs' + suffix]) : [],
    ...(props['sm' + suffix] !== undefined ? this.media.sm`${template(props['sm' + suffix])}` : []),
    ...(props['md' + suffix] !== undefined ? this.media.md`${template(props['md' + suffix])}` : []),
    ...(props['lg' + suffix] !== undefined ? this.media.lg`${template(props['lg' + suffix])}` : []),
    ...(props['xl' + suffix] !== undefined ? this.media.xl`${template(props['xl' + suffix])}` : []),
  ].join('\n');
};

const media = Object.keys(breakpoints).reduce((acc: Partial<Media>, label) => {
  acc[label] = (styles, ...args) => css`
    @media (min-width: ${Number.isInteger(breakpoints[label]) ? breakpoints[label] + 'px' : breakpoints[label]}) {
      ${[styles, ...args]};
    }
  `;
  return acc;
}, mediaFunc as {}) as Media & CommonMedia;

const mediaMax = Object.keys(breakpoints).reduce((acc: Partial<Media>, label) => {
  acc[label] = (styles, ...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${[styles, ...args]};
    }
  `;
  return acc;
}, {}) as Media;

const shadows = {
  cardBoxShadow: '0 5px 16px rgba(0, 0, 0, 0.2)',
};

const spacing = ['0', '0.25rem', '0.5rem', '1rem', '1.5rem', '3rem'];

declare module '@emotion/react' {
  export interface Theme {
    breakpoints: CommonBreakpoints;
    colors: CommonColors;
    media: CommonMedia;
    mediaMax: Media;
    shadows: { [p in keyof typeof shadows]: string };
    spacing: string[];
  }
}

const theme = {
  breakpoints,
  colors,
  media,
  mediaMax,
  shadows,
  spacing,
};

export default theme;
