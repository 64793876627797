import { useEffect } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import qs from 'qs';
import Head from 'next/head';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import theme from '../themes/common/theme';
import { Layout } from '@/components/layout';
import { ENV } from '@/constants';
import { parseDebug } from '../util/parseDebug';

import './default-theme.scss';
import 'react-alice-carousel/lib/alice-carousel.css';

const gaTrackingId = ENV.NEXT_PUBLIC_GA_TRACKING_ID;
const ga4TrackingId = ENV.NEXT_PUBLIC_GA_4_TRACKING_ID;
const debug = parseDebug(ENV.NEXT_PUBLIC_DEBUG);
const apiHostname = ENV.NEXT_PUBLIC_CLIENT_API_HOST;

// Configure axios
axios.defaults.baseURL = apiHostname;
axios.defaults.withCredentials = true;

/**
 * Ensures correct serialisation of query params for our API
 */
axios.defaults.paramsSerializer = params =>
  qs.stringify(params, {
    arrayFormat: 'brackets',
  });

/**
 * Merges headers with defaults
 */
axios.interceptors.request.use(function (config) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // The following headers prevent IE11 from caching all API requests:
    'Cache-control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Expires: 0,
  };

  config.headers = Object.assign({}, defaultHeaders, config.headers || {});

  return config;
});

const DiscoverPublicApp: React.ElementType<AppProps> = props => {
  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <title>{ENV.NEXT_PUBLIC_ORGANISATION_NAME} Campaigns</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>

      <EmotionThemeProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>

        <Telemetry />
      </EmotionThemeProvider>
    </>
  );
};

/**
 * Attaches telemetry hooks to the app
 * TODO: Move to @fusion/reporting
 */
const Telemetry = () => {
  const router = useRouter();

  useEffect(() => {
    let legacyGAInitialised = false;
    let ga4Initialised = false;

    // Legacy Google Analytics (Universal Analytics)
    // Remove after July 1st 2023, fully discontinued by Google
    if (gaTrackingId) {
      ReactGA.initialize(gaTrackingId, {
        debug: debug.googleAnalytics,
      });

      // Log the initial page view
      ReactGA.pageview(window.location.pathname + window.location.search);

      legacyGAInitialised = true;
    }

    // Google Analytics 4
    if (ga4TrackingId) {
      ReactGA4.initialize([
        {
          trackingId: ga4TrackingId,
        },
      ]);

      ga4Initialised = true;
    }

    // Register handler for page view events
    const handleRouteChange = () => {
      if (legacyGAInitialised) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }

      /**
       * GA4 sends page_view events automatically when the browser history changes
       * NB: this behaviour is not visible when working locally, it only works
       * when GA4 is initialised with a valid tracking ID. Uncomment this block
       * for local testing if needed.
       */
      // if (ga4Initialised) {
      //   ReactGA4.send({
      //     hitType: 'pageview',
      //     page: window.location.pathname + window.location.search,
      //   });
      // }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return null;
};

export default DiscoverPublicApp;
