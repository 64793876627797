import { ReactElement } from 'react';

type LogoProps = {
  title?: string;
};

export default function Logo({ title, ...props }: LogoProps): ReactElement {
  return (
    <svg width="112" height="40" viewBox="0 0 112 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M111.231 12.1286C110.937 11.8718 110.555 11.7374 110.164 11.753H107.516V7.2566C107.522 7.04935 107.486 6.84299 107.412 6.64957C107.337 6.45614 107.225 6.27952 107.081 6.13001C106.772 5.85085 106.371 5.69629 105.954 5.69629C105.538 5.69629 105.137 5.85085 104.828 6.13001C104.684 6.27952 104.572 6.45614 104.497 6.64957C104.422 6.84299 104.387 7.04935 104.393 7.2566L104.393 27.0157C104.386 27.2262 104.424 27.4357 104.502 27.6313C104.58 27.8268 104.698 28.0041 104.848 28.1521C105.157 28.4447 105.568 28.6077 105.994 28.6077C106.42 28.6077 106.83 28.4447 107.14 28.1521C107.285 28.0014 107.398 27.8232 107.473 27.628C107.548 27.4328 107.582 27.2246 107.575 27.0157L107.575 14.441H110.164C110.555 14.4566 110.937 14.3223 111.231 14.0655C111.368 13.9411 111.475 13.788 111.545 13.617C111.615 13.4461 111.647 13.2617 111.637 13.0773C111.637 12.8999 111.601 12.7243 111.532 12.5612C111.462 12.398 111.36 12.2508 111.231 12.1286Z"
        fill="#15154C"
      />
      <path
        d="M101.805 11.8994C102.064 11.9984 102.289 12.1691 102.454 12.3913C102.619 12.6136 102.717 12.8782 102.736 13.154C102.75 13.2556 102.75 13.3586 102.736 13.4602C102.686 13.7488 102.531 14.0088 102.301 14.1909C102.071 14.373 101.782 14.4645 101.488 14.448C101.332 14.4425 101.176 14.4227 101.023 14.3888C100.35 14.259 99.668 14.1863 98.9831 14.1714C97.6964 14.0988 96.4208 14.4455 95.3489 15.1593C94.9111 15.4754 94.5577 15.8937 94.3194 16.3776C94.0812 16.8615 93.9655 17.3962 93.9824 17.9351V27.0527C94.0045 27.4745 93.8626 27.8885 93.5863 28.2085C93.2539 28.4672 92.8443 28.6078 92.4227 28.6078C92.0011 28.6078 91.5916 28.4672 91.2592 28.2085C91.1136 28.0533 91.0023 27.8694 90.9324 27.6686C90.8625 27.4678 90.8355 27.2547 90.8532 27.0429V13.154C90.8375 12.9438 90.8654 12.7327 90.9353 12.5338C91.0052 12.335 91.1154 12.2051 91.2592 12.0507C91.414 11.9072 91.5968 11.7973 91.7961 11.7276C91.9955 11.6579 92.0947 11.6456 92.4178 11.6456C92.4178 11.6456 92.8465 11.6361 93.0499 11.7041C93.2532 11.7721 93.438 11.8862 93.5896 12.0374C93.7413 12.1887 93.8556 12.3206 93.9238 12.5234C93.9919 12.7263 94.012 12.9421 93.9824 13.154V13.7368C94.6147 13.0008 95.4027 12.4138 96.2896 12.018C97.2229 11.5981 98.2366 11.3858 99.2604 11.3956C100.136 11.367 101.007 11.5394 101.805 11.8994Z"
        fill="#15154C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.3047 19.9637C88.3054 19.9007 88.3054 19.8376 88.3047 19.7747C88.3047 19.7747 88.3047 13.5121 88.3047 13.2721C88.3047 13.0322 88.3044 12.7922 88.2354 12.5869C88.1664 12.3817 88.0506 12.1952 87.897 12.0422C87.7435 11.8891 87.5564 11.7736 87.3506 11.7049C87.1447 11.6361 86.8606 11.6457 86.7106 11.6457C86.1011 11.6457 85.8722 11.7369 85.5476 12.0056C85.4035 12.1628 85.2928 12.3474 85.2222 12.5484C85.1515 12.7493 85.1224 13.0097 85.1365 13.2222V13.3201C84.7849 13.0324 84.4096 12.7722 84.0136 12.5425C82.6686 11.774 81.1423 11.3773 79.5921 11.3931C78.0337 11.3765 76.4983 11.7695 75.1406 12.5325C73.8196 13.2763 72.7302 14.1793 71.9925 15.5009C71.2139 16.8817 70.8193 18.4448 70.8495 20.0285C70.8251 21.5827 71.1904 23.1184 71.9123 24.4962C72.5839 25.7712 73.5884 26.72 74.8198 27.473C76.0656 28.2311 77.501 28.6227 78.9605 28.6024C80.1297 28.6165 81.2868 28.3655 82.3445 27.8686C83.4022 27.3717 84.4027 26.7634 85.1361 25.8555L85.1365 27.0333C85.1186 27.2476 85.1459 27.4632 85.2167 27.6664C85.2875 27.8695 85.4002 28.0556 85.5476 28.2126C85.8717 28.4917 86.2933 28.6318 86.7206 28.6024C86.9356 28.6203 87.1519 28.5931 87.3557 28.5225C87.5595 28.4519 87.7461 28.3396 87.9037 28.1927C88.1847 27.8736 88.3287 27.4572 88.3047 27.0333V19.9637ZM82.4595 25.1159C83.3088 24.6046 84.0021 23.872 84.4647 22.997C84.9601 22.0757 85.1507 21.0138 85.1353 19.9686C85.1455 18.9239 84.9552 17.9233 84.4647 17.0001C84.1127 16.3328 83.6278 15.744 83.0398 15.2698C82.4518 14.7956 81.7731 14.4458 81.0449 14.2419C80.3168 14.038 79.5546 13.9842 78.8049 14.0838C78.0552 14.1834 77.3338 14.4343 76.6846 14.8212C75.8365 15.3384 75.1439 16.0738 74.6794 16.9501C74.1814 17.8708 73.9261 18.9026 73.9375 19.9486C73.9207 20.9952 74.1764 22.0282 74.6794 22.947C75.1473 23.8182 75.8394 24.5496 76.6846 25.0659C77.5557 25.5697 78.5449 25.835 79.552 25.835C80.5591 25.835 81.5884 25.6197 82.4595 25.1159Z"
        fill="#15154C"
      />
      <ellipse cx="53.5694" cy="37.9112" rx="1.96203" ry="1.96203" fill="#15154C" />
      <path
        d="M68.0091 15.6193C67.2782 14.3212 66.2058 13.2472 64.9077 12.5136C63.5749 11.762 62.0668 11.3755 60.5362 11.3932C59.0169 11.376 57.5208 11.7663 56.2041 12.5234C54.9196 13.2638 53.8559 14.3324 53.1224 15.6193C52.352 16.9582 51.9715 18.4784 51.9877 20.0224V24.5238C51.9877 24.5238 51.9877 24.5238 51.9877 24.5827L51.9877 34.4306C52.4582 34.1225 52.993 33.9843 53.5556 33.9914C54.1179 33.9873 54.669 34.1251 55.1408 34.4306L55.1408 28.3273C55.1408 28.3273 55.1408 28.3273 55.1408 28.2683V25.8014C55.862 26.6955 56.7775 27.4141 57.818 27.9028C58.8586 28.3915 59.9968 28.6375 61.1466 28.6222C62.5767 28.6418 63.9831 28.2568 65.2031 27.5116C66.4232 26.7753 67.4202 25.7223 68.0878 24.4648C68.772 23.1031 69.1101 21.5944 69.0724 20.0715C69.1154 18.52 68.7487 16.9846 68.0091 15.6193ZM65.3507 22.9807C64.8905 23.8368 64.211 24.5558 63.3816 25.0643C62.7511 25.4477 62.0488 25.6983 61.3178 25.8008C60.5868 25.9033 59.8425 25.8555 59.1306 25.6604C58.4188 25.4652 57.7544 25.1269 57.1783 24.6661C56.6023 24.2052 56.1267 23.6317 55.7807 22.9807C55.2943 22.0747 55.047 21.06 55.062 20.0322C55.0521 19.005 55.2991 17.9916 55.7807 17.0837C56.131 16.437 56.6086 15.8677 57.1849 15.4099C57.7612 14.9521 58.4242 14.6153 59.1342 14.4198C59.8441 14.2242 60.5864 14.174 61.3163 14.272C62.0462 14.3701 62.7487 14.6144 63.3816 14.9903C64.2135 15.5 64.8934 16.2229 65.3507 17.0837C65.8354 17.9908 66.0858 19.0042 66.0793 20.0322C66.0926 21.0642 65.8418 22.0824 65.3507 22.9906V22.9807Z"
        fill="#15154C"
      />
      <path
        d="M46.0289 12.3318C47.1719 12.9592 48.1063 13.9073 48.7169 15.0593C49.4013 16.3975 49.741 17.8852 49.7051 19.3878L49.7051 27.0167C49.7051 27.4387 49.5375 27.8434 49.2391 28.1418C48.9407 28.4401 48.536 28.6078 48.1141 28.6078C47.6921 28.6078 47.2874 28.4401 46.989 28.1418C46.6906 27.8434 46.523 27.4387 46.523 27.0167L46.523 19.3878C46.574 18.6914 46.4801 17.9919 46.247 17.3336C46.014 16.6754 45.647 16.0726 45.1691 15.5633C44.6826 15.121 44.1135 14.7789 43.4946 14.5568C42.8756 14.3346 42.2189 14.2368 41.5621 14.2688C40.7151 14.2452 39.8747 14.4248 39.1113 14.7925C38.4331 15.1157 37.8532 15.6137 37.4313 16.2353C37.0313 16.8168 36.8176 17.5061 36.8186 18.2118V27.0169C36.8248 27.2257 36.7895 27.4336 36.7149 27.6287C36.6403 27.8237 36.5277 28.0021 36.3838 28.1534C36.0711 28.4444 35.6597 28.6063 35.2325 28.6063C34.8053 28.6063 34.3939 28.4444 34.0812 28.1534C33.9341 28.0039 33.8189 27.8261 33.7424 27.6307C33.666 27.4354 33.63 27.2266 33.6365 27.0169V13.1422C33.6373 12.9225 33.6854 12.7055 33.7775 12.506C33.8696 12.3065 34.0035 12.1291 34.1701 11.986C34.488 11.7053 34.8974 11.5504 35.3214 11.5504C35.7455 11.5504 36.1549 11.7053 36.4727 11.986C36.62 12.139 36.7346 12.3204 36.8093 12.5192C36.8841 12.718 36.9175 12.93 36.9075 13.1422V13.4881C37.5807 12.8105 38.3888 12.2819 39.2793 11.9365C40.2235 11.5669 41.23 11.3824 42.244 11.393C43.5638 11.3887 44.8641 11.7113 46.0289 12.3318Z"
        fill="#15154C"
      />
      <path
        d="M26.6712 13.418V26.9939C26.6671 27.2069 26.7049 27.4185 26.7823 27.6163C26.8597 27.814 26.9751 27.9939 27.1217 28.1453C27.2684 28.2971 27.4442 28.4163 27.638 28.4955C27.8318 28.5748 28.0395 28.6123 28.2481 28.6058C28.4602 28.617 28.6722 28.5817 28.8699 28.5023C29.0675 28.4229 29.2463 28.3011 29.3941 28.1453C29.5394 27.9934 29.6533 27.8132 29.7291 27.6154C29.8048 27.4176 29.8407 27.2062 29.8348 26.9939V13.418C29.3624 13.7264 28.8136 13.8903 28.253 13.8903C27.6925 13.8903 27.1436 13.7264 26.6712 13.418Z"
        fill="#15154C"
      />
      <ellipse cx="28.253" cy="9.93664" rx="1.96203" ry="1.96203" fill="#15154C" />
      <ellipse cx="10.2532" cy="18.2532" rx="10.2532" ry="10.2532" fill="#4649FF" />
      <circle cx="13.3545" cy="21.3547" r="2.59494" fill="white" />
      <circle cx="7.15207" cy="15.1516" r="2.59494" fill="white" />
    </svg>
  );
}
